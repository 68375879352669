
<template>

    <div class="fade-in" style="overflow: scroll;">
      <div>
        <h1 class="text-black-600 p-6  font-semibold">
      Sign up for free!
    </h1>

        <div class="flex align-items justify-center">
          <input
            type="email"
            name="email"
            id="username"
            class="border py-2 px-3 text-grey-darkest w-full opacity-90"
            placeholder="email"
          />
        </div>
        <div class="flex align-items justify-center">
          <input
            type="password"
            name="password"
            id="password"
            class="border py-2 px-3 text-grey-darkest w-full opacity-90"
            placeholder="password"
          />
        </div>
        <div class="flex align-items justify-center">
          <input
            type="password"
            name="retype-password"
            id="retype-password"
            class="border py-2 px-3 text-grey-darkest w-full opacity-90"
            placeholder="retype password"
          />
        </div>   

        <div class="flex align-items justify-center">
          <input
            type="text"
            name="first-name"
            id="first-name"
            class="border py-2 px-3 text-grey-darkest w-full opacity-90"
            placeholder="first name"
          />
        </div>
        <div class="flex align-items justify-center">
          <input
            type="text"
            name="last-name"
            id="last-name"
            class="border py-2 px-3 text-grey-darkest w-full opacity-90"
            placeholder="last name"
          />
        </div>
        <div class="flex align-items justify-center">
          <input
            type="text"
            name="street-address"
            id="street-address"
            class="border py-2 px-3 text-grey-darkest w-full opacity-90"
            placeholder="street address"
          />
        </div>
        <div class="flex align-items justify-center">
          <input
            type="text"
            name="city"
            id="city"
            class="border py-2 px-3 text-grey-darkest w-full opacity-90"
            placeholder="city"
          />
        </div>
        <div class="flex align-items justify-center">
          <input
            type="text"
            name="state"
            id="state"
            class="border py-2 px-3 text-grey-darkest w-full opacity-90"
            placeholder="state"
          />
        </div>
        <div class="flex align-items justify-center">
          <input
            type="text"
            name="post-code"
            id="post-code"
            class="border py-2 px-3 text-grey-darkest w-full opacity-90"
            placeholder="post code"
          />
        </div>
        <div class="flex align-items justify-center">
          <input
            type="text"
            name="country"
            id="country"
            class="border py-2 px-3 text-grey-darkest w-full opacity-90"
            placeholder="country"
          />
        </div>
        <div class="flex align-items justify-center">
          <input
            type="text"
            name="phone-number"
            id="phone-number"
            class="border py-2 px-3 text-grey-darkest w-full opacity-90"
            placeholder="phone number"
          />
        </div>

        <div>
          <button
            
            class="flex align-items justify-start bg-green-400 hover:bg-green-500 text-white text-lg p-2 rounded"
            
          >
            Sign Up
          </button>
        </div>
  
      </div>

    </div>
  </template>
  
  <style scoped>
  #container {
    text-align: center;
  
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
  #container strong {
    font-size: 20px;
    line-height: 26px;
  }
  
  #container p {
    font-size: 16px;
    line-height: 22px;
  
    color: #8c8c8c;
  
    margin: 0;
  }
  
  #container a {
    text-decoration: none;
  }
  
  .fade-in {
    animation: fadeIn 5s;
    width: 100%;
    height: 950px;
    background-image: url("@/assets/main.svg");
    background-repeat: no-repeat;
  }
  
  #copy {
    position: absolute;
    bottom: 0;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  </style>
  