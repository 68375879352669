import { Store, PiniaCustomStateProperties } from "pinia";

export function login(store: any, usr: any, pass: any) {

    if (
        usr === store.info[0].email &&
        pass === store.info[0].password
    ) {

        store.loggedIn = true;

        store.credentials = false;

        return true;
    }
}