import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import SignUpPage from '../views/SignUpPage.vue';
import AppPage from '../views/AppPage.vue';

const routes: Array<RouteRecordRaw> = [

  {
    path: '/',
    name: 'Login',
    component: HomePage
  }
  ,
  {
    path: '/signup',
    name: 'Sign Up',
    component: SignUpPage
  }
  ,
  {
    path: '/app',
    name: 'App',
    component: AppPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router


