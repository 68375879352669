import { defineStore } from 'pinia'
export const useCounterStore = defineStore('counter', {
    state: () => ({ loggedIn: false, credentials: true, info: null }),
    getters: {
        doubleCount: (state) => { state.loggedIn = false; state.credentials = true; },
    },
    actions: {
        increment() {

            this.loggedIn = true;

            this.credentials = false;

            this.info = null;

        },
    },
});